import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from "../components/Footer";

import "./investment.css";
import "../components/About/about.css";
const SecondPage = () => 
{ 
  
  if (typeof window !== 'undefined') {  
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/5ed665f94a7c62581799c5a5/default';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
    console.log('Chat Working')
  }
  else {
    console.log("Sorry Chat Not Working")
  }
  return (
    <Layout className="gallery">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/css/bootstrap.min.css" />      
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/1.4.6/tailwind.min.css" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" />
          <SEO title="Investment Portfolio " description="Investment Portfolio - Coming Soon" />
          <Container fluid className={"about-container investment-block"}>
            <Row>
                <Col lg={12} className={"no-pad-col"}>
                    <section className={"c-section"}>
                        <h2 className={"c-section__title pr-0 pl-0"}><span>Coming Soon</span></h2>
                        
                    </section>
                </Col>
            </Row>
          </Container>    
      <Footer />
    </Layout>
  )
}
export default SecondPage
